import './style.scss';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  IconButton,
  Snackbar,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import mainAPI from '../../../utils/mainAPI';
// import fbserviceAPI from "../../utils/fbserviceAPI";
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import UserCard from './UserCard';

function Users() {
  const [allUsersList, setAllUsersList] = useState([]);
  const [createNewUserDialog, setCreateNewUserDialog] = useState(false);
  const [changeUserPasswordDialog, setChangeUserPasswordDialog] = useState(false);
  const [changePasswordUserName, setChangePasswordUserName] = useState(null);
  const [userRolesDialog, setUserRolesDialog] = useState(false);
  const [userGroupsDialog, setUserGroupsDialog] = useState(false);
  const [newUserLogin, setNewUserLogin] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [pass1, setPass1] = useState(null);
  const [pass2, setPass2] = useState(null);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [rolesChecked, setRolesChecked] = React.useState([]);
  const [rolesLeft, setRolesLeft] = React.useState([0, 1, 2, 3]);
  const [rolesRight, setRolesRight] = React.useState([4, 5, 6, 7]);
  const rolesLeftChecked = intersection(rolesChecked, rolesLeft);
  const rolesRightChecked = intersection(rolesChecked, rolesRight);
  const [rolesUserName, setRolesUserName] = useState(null);

  const [groupsChecked, setGroupsChecked] = React.useState([]);
  const [groupsLeft, setGroupsLeft] = React.useState([0, 1, 2, 3]);
  const [groupsRight, setGroupsRight] = React.useState([4, 5, 6, 7]);
  const groupsLeftChecked = intersection(groupsChecked, groupsLeft);
  const groupsRightChecked = intersection(groupsChecked, groupsRight);
  const [groupsUserName, setGroupsUserName] = useState(null);
  const [allGroups, setAllGroups] = useState([]);
  const [openUserCard, setOpenUserCard] = useState(false);
  const [userCardData, setUserCardData] = useState(null);
  useEffect(() => {
    document.title = '2leads App - Пользователи';
    loadUsers();
  }, []);
  function loadUsers() {
    mainAPI.get('/primary/all-users').then((res) => {
      setAllUsersList(res.data);
    });
    // fbserviceAPI.get("/v2/admin/group/all").then((response) => {
    //   const temp = [];
    //   if (response.data.new) {
    //     response.data.new.forEach((item) => {
    //       temp.push(item + " New");
    //     });
    //   }
    //   if (response.data.old) {
    //     response.data.old.forEach((item) => {
    //       temp.push(item + " Old");
    //     });
    //   }
    //   setAllGroups(temp);
    // });
  }
  const allRoles = ['user', 'admin', 'admin_caps', 'faq_admin', 'buyer', 'farmer'];

  function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
  }

  function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
  }

  function union(a, b) {
    return [...a, ...not(b, a)];
  }

  function submitCreateDialog() {
    if (newUserLogin && newUserPassword) {
      mainAPI
        .post('/primary/create-user', {
          username: newUserLogin,
          password: newUserPassword,
        })
        .then((res) => {
          if (res.status === 200) {
            mainAPI.get('/primary/all-users').then((res2) => {
              setAllUsersList(res2.data);
            });
            setSuccessToast(true);
          }
        })
        .catch((e) => setErrorToast(true));
      setCreateNewUserDialog(false);
    }
  }

  function openChangePasswordDialog(e) {
    setChangePasswordUserName(e.currentTarget.dataset.name);
    setChangeUserPasswordDialog(true);
  }

  function submitChangePassword() {
    if (pass1 === pass2) {
      mainAPI
        .post('/primary/change-password', {
          username: changePasswordUserName,
          password: pass1,
        })
        .then((res) => {
          if (res.status === 200) {
            setSuccessToast(true);
            setChangeUserPasswordDialog(false);
          }
        });
    }
  }

  function makeRows() {
    return allUsersList.map((item) => {
      return (
        <Paper className='userItem' key={item.login}>
          <p>{item.login}</p>
          <div className='userItem-buttons-wrapper'>
            <Button
              onClick={() => {
                setChangeUserPasswordDialog(true);
                setChangePasswordUserName(item.login);
              }}
            >
              Change Password
            </Button>
            <Button
              onClick={() => {
                setUserCardData(item);
                setOpenUserCard(true);
              }}
            >
              Edit
            </Button>
          </div>
        </Paper>
      );
    });
  }

  function loadUserRoles(e) {
    setUserRolesDialog(true);
    setRolesUserName(e.currentTarget.dataset.name);
    mainAPI.get('/primary/user-roles?login=' + e.currentTarget.dataset.name).then((res) => {
      setRolesLeft(res.data);
      setRolesRight(not(allRoles, res.data));
    });
  }

  function updateUserGroups(e) {
    const old_binom = [];
    const new_binom = [];

    groupsRight.forEach((item) => {
      if (item.indexOf(' New') !== -1) {
        new_binom.push(item.replace(' New', ''));
      }
    });
    groupsRight.forEach((item) => {
      if (item.indexOf(' Old') !== -1) {
        old_binom.push(item.replace(' Old', ''));
      }
    });
    const payload = {
      login: groupsUserName,
      groups: {
        new: new_binom,
        old: old_binom,
      },
    };

    // fbserviceAPI.post("/v2/admin/user/set-groups", payload).then((res) => {
    //   if (res.status === 200) {
    //     setSuccessToast(true);
    //   }
    // });
    setUserGroupsDialog(false);
  }

  const handleRolesToggle = (value) => () => {
    const currentIndex = rolesChecked.indexOf(value);
    const newChecked = [...rolesChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setRolesChecked(newChecked);
  };
  const handleGroupsToggle = (value) => () => {
    const currentIndex = groupsChecked.indexOf(value);
    const newChecked = [...groupsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setGroupsChecked(newChecked);
  };

  const rolesNumberOfChecked = (items) => intersection(rolesChecked, items).length;
  const groupsNumberOfChecked = (items) => intersection(rolesChecked, items).length;

  const handleRolesToggleAll = (items) => () => {
    if (rolesNumberOfChecked(items) === items.length) {
      setRolesChecked(not(rolesChecked, items));
    } else {
      setRolesChecked(union(rolesChecked, items));
    }
  };
  const handleGroupsToggleAll = (items) => () => {
    if (groupsNumberOfChecked(items) === items.length) {
      setGroupsChecked(not(groupsChecked, items));
    } else {
      setGroupsChecked(union(groupsChecked, items));
    }
  };
  const handleRolesCheckedRight = () => {
    setRolesRight(rolesRight.concat(rolesLeftChecked));
    setRolesLeft(not(rolesLeft, rolesLeftChecked));
    setRolesChecked(not(rolesChecked, rolesLeftChecked));
  };
  const handleGroupsCheckedRight = () => {
    setGroupsRight(groupsRight.concat(groupsLeftChecked));
    setGroupsLeft(not(groupsLeft, groupsLeftChecked));
    setGroupsChecked(not(groupsChecked, groupsLeftChecked));
  };

  const handleRolesCheckedLeft = () => {
    setRolesLeft(rolesLeft.concat(rolesRightChecked));
    setRolesRight(not(rolesRight, rolesRightChecked));
    setRolesChecked(not(rolesChecked, rolesRightChecked));
  };
  const handleGroupsCheckedLeft = () => {
    setGroupsLeft(groupsLeft.concat(groupsRightChecked));
    setGroupsRight(not(groupsRight, groupsRightChecked));
    setGroupsChecked(not(groupsChecked, groupsRightChecked));
  };

  const customRolesList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleRolesToggleAll(items)}
            checked={rolesNumberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={rolesNumberOfChecked(items) !== items.length && rolesNumberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${rolesNumberOfChecked(items)}/${items.length} выбрано`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((value) => {
          return value !== 'user' ? (
            <>
              <ListItem key={value} role='listitem' button onClick={handleRolesToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={rolesChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': `"transfer-list-all-item-${value}-label"`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={`"transfer-list-all-item-${value}-label"`} primary={value} />
              </ListItem>
            </>
          ) : null;
        })}
        <ListItem />
      </List>
    </Card>
  );
  const customGroupsList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleGroupsToggleAll(items)}
            checked={groupsNumberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={groupsNumberOfChecked(items) !== items.length && groupsNumberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${groupsNumberOfChecked(items)}/${items.length} выбрано`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((value) => {
          return value !== 'user' ? (
            <>
              <ListItem key={value} role='listitem' button onClick={handleGroupsToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={groupsChecked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': `"transfer-list-all-item-${value}-label"`,
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={`"transfer-list-all-item-${value}-label"`} primary={value} />
              </ListItem>
            </>
          ) : null;
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      {openUserCard ? (
        <UserCard
          close={() => setOpenUserCard(false)}
          refresh={() => loadUsers()}
          open={openUserCard}
          data={userCardData}
        />
      ) : null}
      <Paper className='usersTable'>
        <div className='userItem'>
          <Button variant='contained' color='primary' onClick={setCreateNewUserDialog}>
            Создать пользователя
          </Button>
        </div>
        {makeRows()}
      </Paper>
      <Dialog
        open={createNewUserDialog}
        onClose={() => setCreateNewUserDialog(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Добавление пользователя</DialogTitle>
        <DialogContent>
          <DialogContentText>Введите логин и пароль нового пользователя.</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Логин'
            type='login'
            fullWidth
            value={newUserLogin}
            onChange={(e) => setNewUserLogin(e.target.value)}
          />
          <TextField
            margin='dense'
            id='password'
            label='Пароль'
            type='password'
            fullWidth
            value={newUserPassword}
            onChange={(e) => setNewUserPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateNewUserDialog(false)} color='primary'>
            Отмена
          </Button>
          <Button onClick={submitCreateDialog} color='primary'>
            Создать
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={userGroupsDialog} onClose={() => setUserGroupsDialog(false)} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Группы пользователя</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item>{customGroupsList('Возможные', groupsLeft)}</Grid>
            <Grid item>
              <Grid container direction='column' alignItems='center'>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleGroupsCheckedRight}
                  disabled={groupsLeftChecked.length === 0}
                  aria-label='Группы пользователя'
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant='outlined'
                  size='small'
                  onClick={handleGroupsCheckedLeft}
                  disabled={groupsRightChecked.length === 0}
                  aria-label='Возможные группы'
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customGroupsList('Текущие', groupsRight)}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={updateUserGroups} color='primary'>
            Применить
          </Button>
          <Button onClick={() => setUserGroupsDialog(false)} color='primary'>
            Отмена
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={changeUserPasswordDialog}
        onClose={() => setChangeUserPasswordDialog(false)}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Смена пароля для {changePasswordUserName}</DialogTitle>
        <DialogContent>
          <DialogContentText>Введите новый пароль</DialogContentText>
          <TextField
            autoFocus
            margin='dense'
            id='pass1'
            label='Пароль'
            type='password'
            fullWidth
            value={pass1}
            onChange={(e) => setPass1(e.target.value)}
          />
          <DialogContentText style={{ marginTop: 40 }}>Повторите пароль</DialogContentText>
          <TextField
            margin='dense'
            id='pass2'
            label='Пароль'
            type='password'
            fullWidth
            value={pass2}
            onChange={(e) => setPass2(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setChangeUserPasswordDialog(false)} color='primary'>
            Отмена
          </Button>
          <Button onClick={submitChangePassword} color='primary'>
            Создать
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={successToast}
        severity='success'
        autoHideDuration={1500}
        onClose={() => setSuccessToast(false)}
        message='Данные обновлены'
        action={
          <>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setSuccessToast(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={errorToast}
        autoHideDuration={1500}
        onClose={() => setErrorToast(false)}
        message='Ошибка'
        action={
          <>
            <IconButton size='small' aria-label='close' color='inherit' onClick={() => setErrorToast(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </>
        }
      />
    </>
  );
}

export default observer(Users);
