import React, { useCallback, useEffect, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import EditIcon from "@mui/icons-material/Edit";
import {
  Typography,
  Container,
  Button,
  TextField,
  CssBaseline,
  Select,
  IconButton,
  Chip,
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import mainAPI from "../../utils/mainAPI";
import useStore from "../../hooks/useStore";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import "react-dropzone-uploader/dist/styles.css";
import "./style.scss";
import Paper from "@mui/material/Paper";
import { NavLink } from "react-router-dom";
import cyrillicToTranslit from "cyrillic-to-translit-js";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import FaqSettings from "./FaqSettings";
import FaqColorTags from "./FaqColorTags";

const FAQ = ({ match }) => {
  const [editMode, setEditMode] = useState(false);
  const [loadedTags, setLoadedTags] = useState([]);
  const [loadedArticle, setLoadedArticle] = useState([]);
  const [editModeArticleName, setEditModeArticleName] = useState("");
  const [editModeArticleCategoty, setEditModeArticleCategoty] = useState(null);
  const [editModeArticleContent, setEditModeArticleContent] = useState("");
  const [currentTags, setCurrentTags] = useState([]);
  const [newArticleMenuOpened, setNewArticleMenuOpened] = useState(false);
  const [newArticleMenuAnchor, setNewArticleMenuAnchor] = useState(null);
  const [settingsDialog, setSettingsDialog] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [categories, setCategories] = useState([]);

  const { appStore } = useStore();

  useEffect(() => {
    document.title = "2leads App - FAQ";
    loadCategories();
    loadTags();
    if (match) {
      loadArticleByUrl(match.split("/")[2]);
    }
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    let fd = new FormData();

    acceptedFiles.map((file) => {
      fd.append("image", file);
    });

    mainAPI
      .post("/faq/uploadimage", fd, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status == 200) {
          navigator.clipboard.writeText(res.data.path);
          toast.success("Ссылка для встраивания скопирована в буфер обмена");
        } else {
          toast.error("ERROR");
        }
      })
      .catch((err) => {
        toast.error("ERROR");
      });
  }, []);
  const { getRootProps } = useDropzone({ onDrop });

  async function loadCategories() {
    try {
      const data = await mainAPI.get("/faq/categories");
      setCategories(data.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadTags() {
    try {
      const data = await mainAPI.get("/faq/tags");
      setLoadedTags(data.data);
    } catch (e) {
      console.log(e);
    }
  }
  async function loadArticle(id) {
    const data = await mainAPI.get("/faq/article?id=" + id);
    setLoadedArticle(data.data);
    document.title = "FAQ - " + data.data?.name;
  }

  async function loadArticleByUrl(url) {
    const data = await mainAPI.get("/faq/article?url=" + url);
    setLoadedArticle(data.data);
    document.title = "FAQ - " + data.data?.name;
  }

  function makeTree() {
    const result = [];
    categories.forEach((item) => {
      if (item.articles.length) {
        result.push(
          <TreeItem
            style={{ textAlign: "left", marginBottom: "10px" }}
            nodeId={item._id}
            label={item.name}
          >
            {item.articles.map((el) => {
              return (
                <NavLink
                  to={"/faq/" + el.url}
                  style={{ color: "#fff", textDecoration: "none" }}
                >
                  <TreeItem
                    style={{ textAlign: "left", marginBottom: "10px" }}
                    nodeId={el.id}
                    onClick={() => handleArticleClick(el.name, el.id)}
                    label={el.name}
                  ></TreeItem>
                </NavLink>
              );
            })}
          </TreeItem>
        );
      }
    });
    if (appStore.getUserRoles().indexOf("faq_admin") !== -1) {
      result.push(
        <TreeItem
          style={{ textAlign: "right" }}
          onClick={handleNewArticleClick}
          nodeId="new"
          label="+"
        ></TreeItem>
      );
    }

    return result;
  }

  async function handleArticleClick(name, id) {
    if (editMode) {
      setEditMode(false);
    }
    await loadArticle(id);
  }

  function handleNewArticleClick(e) {
    setNewArticleMenuAnchor(e.target);
    setNewArticleMenuOpened(true);
  }
  function handleArticleContextMenuClick(e, id) {
    e.preventDefault();

    if (isNew) {
      setIsNew(false);
    }
  }

  function editArticle() {
    setEditMode(true);
    setEditModeArticleCategoty(loadedArticle.category);
    setEditModeArticleContent(loadedArticle.content);
    setEditModeArticleName(loadedArticle.name);
    categories.forEach((item) => {
      item.articles.forEach((element) => {
        if (element.id === loadedArticle._id) {
          setEditModeArticleCategoty(item._id);
        }
      });
    });
  }

  function saveEditedArticle() {
    mainAPI
      .post("/faq/updatearticle", {
        id: loadedArticle._id,
        name: editModeArticleName,
        content: editModeArticleContent,
        tags: currentTags.map((i) => i._id),
        category: editModeArticleCategoty,
        url: cyrillicToTranslit().transform(editModeArticleName, "-"),
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Данные обновлены");
          loadCategories();
        }
      })
      .catch((e) => {
        toast.error("ERR");
      });
  }

  function newArticleMode() {
    setEditMode(true);
    setNewArticleMenuOpened(false);
    setIsNew(true);
    setEditModeArticleContent("Новая статья");
    setEditModeArticleName(null);
    setEditModeArticleCategoty(categories[0]._id);
  }

  function saveNewAricle() {
    if (editModeArticleName) {
      mainAPI
        .post("/faq/newarticle", {
          name: editModeArticleName,
          content: editModeArticleContent,
          url: cyrillicToTranslit().transform(editModeArticleName, "-"),
          author: appStore.getUserName(),
          category: editModeArticleCategoty,
        })
        .then((res) => {
          if (res.status == 200) {
            setEditMode(false);
            setIsNew(false);
            loadCategories();
            toast.success("Сохранено");
          } else {
            toast.error("Ошибка");
          }
        })
        .catch((e) => {
          toast.error(e.message);
        });
    } else {
      toast.error("Введите название статьи");
    }
  }
  function deleteArticle() {
    mainAPI
      .post("/faq/deletearticle", {
        id: loadedArticle._id,
      })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Удалено");
          loadCategories();
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  }
  function filterTags(tags) {
    const result = [];
    tags.forEach((element) => {
      const tag = loadedTags.find((i) => i._id === element);
      if (tag) {
        result.push(tag);
      }
    });
    return result;
  }
  function drawTags(articleTags) {
    return filterTags(articleTags).map((item) => (
      <Chip
        label={item.title}
        style={{ background: item.color, marginRight: "10px" }}
      />
    ));
  }
  return (
    <div
      style={{ position: "relative" }}
      {...getRootProps({ onClick: (event) => event.stopPropagation() })}
    >
      <CssBaseline />
      <Typography
        variant="h4"
        style={{
          textAlign: "left",
          position: "absolute",
          left: 50,
          marginBottom: "14px",
        }}
      >
        FAQ
      </Typography>

      <div style={{ display: "flex" }}>
        <TreeView
          aria-label="file system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            height: "fit-content",
            maxHeight: "80vh",
            marginTop: 10,
            position: "sticky",
            top: 0,
            flexGrow: 1,
            width: "fit-content",
            minWidth: 100,
            maxWidth: 250,
            overflowY: "auto",
          }}
        >
          {makeTree()}
        </TreeView>

        {editMode ? (
          <>
            <Container
              style={{
                textAlign: "left",
                overflowY: "scroll",
                paddingTop: "35px",
                margin: "0 auto",
              }}
            >
              <Paper sx={{ p: 2, display: "flex", alignItems: "center" }}>
                <TextField
                  value={editModeArticleName}
                  onChange={(e) => setEditModeArticleName(e.target.value)}
                  label="Название"
                  size="small"
                  style={{ color: "#fff" }}
                />
                <Select
                  size="small"
                  label="category"
                  style={{ width: "200px", margin: "0 20px" }}
                  onChange={(e) => {
                    setEditModeArticleCategoty(e.target.value);
                  }}
                  value={editModeArticleCategoty}
                >
                  {categories?.map((i) => {
                    return (
                      <MenuItem key={i._id} value={i._id}>
                        {i.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem onClick={(e) => setSettingsDialog(true)}>
                    Добавить новую
                  </MenuItem>
                </Select>
                <FaqColorTags
                  setCurrentTags={setCurrentTags}
                  allTags={loadedTags}
                  currentTags={filterTags(loadedArticle.tags)}
                />
                <Button
                  sx={{ ml: 2 }}
                  variant="outlined"
                  onClick={isNew ? saveNewAricle : saveEditedArticle}
                >
                  Cохранить
                </Button>
              </Paper>
              <Paper sx={{ p: 3 }}>
                <MDEditor.Markdown
                  source={editModeArticleContent}
                  style={{ marginTop: "20px", marginBottom: "250px" }}
                />
              </Paper>
            </Container>
            <MDEditor
              value={editModeArticleContent}
              preview="edit"
              style={{
                position: "fixed",
                paddingLeft: "90px",
                bottom: 0,
                left: 0,
                right: 0,
                margin: "0 auto",
                color: "#fff",
              }}
              onChange={setEditModeArticleContent}
            />
          </>
        ) : loadedArticle.content ? (
          <Container sx={{ display: "flex", minHeight: "90vh" }}>
            <Paper
              sx={{
                textAlign: "left",
                width: "100%",
                overflowY: "scroll",
                p: 3,
                marginLeft: 2,
              }}
            >
              {appStore.getUserRoles().indexOf("faq_admin") !== -1 ? (
                <>
                  <IconButton
                    onClick={editArticle}
                    style={{ position: "absolute", right: "70px", top: "10px" }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={deleteArticle}
                    style={{ position: "absolute", right: "30px", top: "10px" }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </>
              ) : null}
              {loadedArticle.tags ? drawTags(loadedArticle.tags) : null}
              <MDEditor.Markdown
                style={{ marginTop: "20px" }}
                className="custom_md"
                source={loadedArticle.content}
              />
            </Paper>
          </Container>
        ) : null}
      </div>
      <Menu
        anchorEl={newArticleMenuAnchor}
        open={newArticleMenuOpened}
        onClose={() => setNewArticleMenuOpened(false)}
      >
        <MenuItem onClick={newArticleMode}>Новая статья</MenuItem>
        <MenuItem
          onClick={() => {
            setSettingsDialog(true);
            setNewArticleMenuOpened(false);
          }}
        >
          Настройки
        </MenuItem>
      </Menu>
      <FaqSettings
        tags={loadedTags}
        categories={categories}
        setCategories={setCategories}
        open={settingsDialog}
        reloadTags={() => loadTags()}
        close={setSettingsDialog}
      />
    </div>
  );
};

export default FAQ;
