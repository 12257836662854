import { observer } from "mobx-react-lite";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Snackbar,
  Button,
  Tab,
  Tabs,
  IconButton,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import "./style.scss";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
// import fbserviceAPI from "../../utils/fbserviceAPI";
// import ConversionsTable from "./ConversionsTable";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import fbserviceAPI2 from "../../utils/fbserviceAPI2";
import CostsTab from "./CostsTab";

import PixelsV3 from "./PixelsV3";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Fbservice() {
  const [allPixels, setAllPixels] = useState([]);
  const [pixelsReady, setPixelsReady] = useState(false);
  const [createPixelDialog, setCreatePixelDialog] = useState(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
  const [tokenDialog, setTokenDialog] = useState(false);
  const [editTokenId, setEditTokenId] = useState(null);
  const [editTokenName, setEditTokenName] = useState(null);
  const [editTokenValue, setEditTokenValue] = useState(null);
  const [tokenWasChanged, setTokenWasChanged] = useState(false);
  const [shareDialog, setShareDialog] = useState(false);
  const [sharePixelId, setSharePixelId] = useState(false);
  const [sharePixelValue, setSharePixelValue] = useState(null);
  const [deletedId, setDeletedId] = useState(null);
  const [createPixelId, setCreatePixelId] = useState(null);
  const [createPixelToken, setCreatePixelToken] = useState(null);
  const [createPixelName, setCreatePixelName] = useState(null);
  const [successToast, setSuccessToast] = useState(false);
  const [errorToast, setErrorToast] = useState(false);
  const [allUsersList, setAllUsersList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [filterValue, setFilterValue] = useState(null);
  const [filterdPixels, setFilterdPixels] = useState([]);

  useEffect(() => {
    document.title = "2leads App - Пиксели";
    // fbserviceAPI
    //   .get("/common/pixel/get-all")
    //   .then((res) => {
    //     setPixelsReady(true);
    //     setAllPixels(res.data);
    //   })
    //   .catch((e) => console.log(e));
  }, []);

  function submitNewPixel(e) {
    e.preventDefault();

    setCreatePixelDialog(false);
  }

  function deletePixelItem() {
    setConfirmDeleteDialog(false);
  }

  function getUserList() {}

  function updateToken() {
    setTokenDialog(false);
  }

  function sharePixel() {
    setSharePixelValue(null);
    setSharePixelId(null);
    setShareDialog(false);
  }

  function skeleton() {
    return (
      <>
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
        <Skeleton
          variant="rect"
          sx={{ width: "100%", margin: "15px", height: 60 }}
        />
      </>
    );
  }

  function handleFilterChange(e) {
    setFilterValue(e.target.value);
    if (e.target.value.length) {
      setFilterdPixels(
        allPixels.filter((item) => {
          return (
            item.pixel_id.indexOf(e.target.value) != -1 ||
            item.pixel_name
              .toLowerCase()
              .indexOf(e.target.value.toLowerCase()) != -1
          );
        })
      );
    } else {
      setFilterdPixels([]);
    }
  }

  function makePixelItems() {
    return (filterValue?.length ? filterdPixels : allPixels)
      .sort((a, b) => a.pixel_id - b.pixel_id)
      .map((item, index) => {
        return (
          <Paper
            className="pixelItem"
            variant={index % 2 !== 0 ? "outlined" : "elevation"}
            key={item.pixel_name + item.access_token}
          >
            <Typography>{item.pixel_name}</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ marginRight: "30px" }}>
                {item.pixel_id}
              </Typography>
              <div className="pixelItem-actions">
                <Button
                  data-id={item.pixel_id}
                  data-name={item.pixel_name}
                  data-token={item.access_token}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setTokenDialog(true);
                    setEditTokenId(e.currentTarget.dataset.id);
                    setEditTokenName(e.currentTarget.dataset.name);
                    setTokenWasChanged(false);
                    setEditTokenValue(e.currentTarget.dataset.token);
                  }}
                >
                  TOKEN
                </Button>
                <IconButton
                  data-id={item.pixel_id}
                  onClick={(e) => {
                    setDeletedId(e.currentTarget.dataset.id);
                    setConfirmDeleteDialog(true);
                  }}
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  data-id={item.pixel_id}
                  onClick={(e) => {
                    getUserList();
                    setSharePixelId(e.currentTarget.dataset.id);
                    setShareDialog(true);
                  }}
                  aria-label="share"
                >
                  <ShareIcon />
                </IconButton>
              </div>
            </div>
          </Paper>
        );
      });
  }

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "left", marginLeft: 50 }}>
        Пиксели
      </Typography>

      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Tabs
            value={activeTab}
            sx={{ marginBottom: "15px", width: "fit-content" }}
            onChange={(e, n) => setActiveTab(n)}
            aria-label="basic tabs example"
          >
            <Tab label="Пиксели v3" />

            <Tab label="Рекламные аккаунты" />
          </Tabs>
        </div>

        <div className="pixelPaper">
          {activeTab === 0 ? <PixelsV3 /> : null}
          {/* {activeTab === 2 ? <ConversionsTable /> : null} */}
          {activeTab === 1 ? <CostsTab /> : null}
        </div>
        <Dialog
          open={createPixelDialog}
          onClose={() => setCreatePixelDialog(false)}
          aria-labelledby="addnewpixel-title"
        >
          <form onSubmit={submitNewPixel}>
            <DialogTitle id="addnewpixel">
              Добавление нового пикселя
            </DialogTitle>
            <Box>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="pixel_name"
                  label="Pixel Name"
                  type="text"
                  required
                  fullWidth
                  value={createPixelName}
                  onChange={(e) => setCreatePixelName(e.target.value)}
                />

                <TextField
                  required
                  margin="dense"
                  id="pixel_id"
                  label="Pixel ID"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.code === "KeyE") {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  value={createPixelId}
                  onChange={(e) => setCreatePixelId(e.target.value)}
                />
                <TextField
                  required
                  margin="dense"
                  id="pixel_token"
                  label="Access Token"
                  type="text"
                  multiline
                  fullWidth
                  value={createPixelToken}
                  onChange={(e) => setCreatePixelToken(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setCreatePixelDialog(false)}
                  color="primary"
                >
                  Отмена
                </Button>
                <Button type="submit" olor="primary">
                  Создать
                </Button>
              </DialogActions>
            </Box>
          </form>
        </Dialog>
        <Dialog
          open={confirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog(false)}
          aria-labelledby="deletepixel-title"
          aria-describedby="deletepixel-description"
        >
          <DialogTitle id="deletepixel-title">
            {"Вы уверены что хотите удалить пиксель " + deletedId + " ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="deletepixel-description">
              Отменить действие будет невозможно
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setConfirmDeleteDialog(false)}
              color="primary"
            >
              Нет
            </Button>
            <Button onClick={deletePixelItem} color="secondary">
              Да
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={tokenDialog}
          onClose={() => setTokenDialog(false)}
          aria-labelledby="watchtoken-title"
          aria-describedby="watchtoken-description"
        >
          <DialogTitle id="watchtoken-slide-title">
            {"Просмотр токена " + editTokenId}
          </DialogTitle>
          <DialogContent>
            <TextField
              value={editTokenValue}
              multiline
              onChange={(e) => {
                setTokenWasChanged(true);
                setEditTokenValue(e.target.value);
              }}
              style={{ width: "100%" }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!tokenWasChanged}
              onClick={updateToken}
              color="secondary"
            >
              Сохранить изменения
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={shareDialog}
          onClose={() => setShareDialog(false)}
          aria-labelledby="sharepixel-title"
          aria-describedby="sharepixel-description"
        >
          <DialogTitle id="sharepixel-title">
            {"Расшарить пиксель "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="sharepixel-description">
              Выберите человека:
            </DialogContentText>
            <FormControl>
              <InputLabel id="sharepixel-select-label">Пользователь</InputLabel>
              <Select
                labelId="sharepixel-select-label"
                id="sharepixel-select"
                value={sharePixelValue}
                autoWidth
                style={{ width: 160 }}
                onChange={(e) => setSharePixelValue(e.target.value)}
              >
                <MenuItem value={null}>-</MenuItem>
                {allUsersList.map((item) => {
                  return (
                    <MenuItem key={item.username} value={item.username}>
                      {item.username}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={sharePixel}
              disabled={sharePixelValue === null}
              color="secondary"
            >
              Поделиться
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={successToast}
          severity="success"
          autoHideDuration={1500}
          onClose={() => setSuccessToast(false)}
          message="Данные обновлены"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setSuccessToast(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={errorToast}
          autoHideDuration={1500}
          onClose={() => setErrorToast(false)}
          message="Ошибка"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setErrorToast(false)}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      </Container>
    </>
  );
}

export default observer(Fbservice);
