import { toast } from 'react-toastify';

import mainAPI from '../../../utils/mainAPI';

import { Box, Button, MenuItem, Paper, Popover, Typography } from '@mui/material';

const modes = {
  1: 'Фрейм',
  2: 'Редирект',
  3: 'Подгрузка',
};

export const PalladiumStreamItem = ({ stream, anchorEls, setAnchorEls, loadStreams, isBlock }) => {
  const handleMenuOpen = (event, streamId) => {
    setAnchorEls((prev) => ({ ...prev, [streamId]: event.currentTarget }));
  };

  const handleMenuClose = (streamId) => {
    setAnchorEls((prev) => ({ ...prev, [streamId]: null }));
  };

  const changeMode = async (id, mode) => {
    try {
      await mainAPI.patch(`/cloaker/palladium/changemode/${id}/${mode}`);
      toast.success('Успешно изменён режим');
      loadStreams();
    } catch (error) {
      const message = error.response?.data?.error?.message || 'Произошла ошибка при смене режима';
      toast.error(message);
    }
  };

  return (
    <Paper
      variant='outlined'
      sx={{
        padding: '10px',
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <Typography variant='h6' sx={{ fontSize: '1rem', width: { xs: '100%', md: 'min(260px, 100%)' } }}>
        {stream.name}
      </Typography>
      <Typography variant='p' sx={{ fontSize: '14px' }}>
        {new Date(stream.createdAt).toLocaleString('ru-RU', { dateStyle: 'short' })}
      </Typography>
      <Box
        sx={{
          width: { xs: '100%', md: 'min(260px, 100%)' },
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '10px',
        }}
      >
        <Typography>Режим:</Typography>
        {isBlock ? (
          <Typography sx={{ color: 'yellow' }}>Блочная интеграция</Typography>
        ) : (
          <Button
            sx={{ width: '170px' }}
            aria-controls='mode-menu'
            aria-haspopup='true'
            variant='contained'
            onClick={(event) => handleMenuOpen(event, stream.id)}
          >
            {modes[stream.target_mode]}
          </Button>
        )}

        <Popover
          id={'mode-menu-' + stream.id}
          open={Boolean(anchorEls[stream.id])}
          anchorEl={anchorEls[stream.id]}
          onClose={() => handleMenuClose(stream.id)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {Object.keys(modes).map((mode) => (
            <MenuItem key={mode} onClick={() => changeMode(stream.id, mode)}>
              {modes[mode]}
            </MenuItem>
          ))}
        </Popover>
      </Box>
    </Paper>
  );
};
