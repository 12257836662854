import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import mainAPI from '../../../../utils/mainAPI';
import useStore from '../../../../hooks/useStore';

import {
  Box,
  IconButton,
  TextField,
  Typography,
  Pagination,
  Select,
  MenuItem,
  Button,
  TableSortLabel,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

import { CustomSkeleton, PalladiumStreamItem } from '../../components';

export const StreamsPalladium = () => {
  const [streams, setStreams] = useState([]);
  const [anchorEls, setAnchorEls] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('date');
  const [order, setOrder] = useState('desc');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(20);

  const { appStore } = useStore();

  const loadStreams = async () => {
    setLoading(true);
    setError(false);

    try {
      const user = appStore.userRoles.includes('admin') ? '' : appStore.userLogin;

      const response = await mainAPI.get(`/cloaker/palladium/getstreams`, {
        params: { user, page, limit, search, sortField: orderBy, sortDirection: order },
      });

      setStreams(response.data.streams);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Не удалось загрузить потоки', error);
      setError(true);
      toast.error('Не удалось загрузить потоки');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadStreams();
  }, [page, limit, search, orderBy, order]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(1);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(1);
  };

  return (
    <>
      <Box
        sx={{
          margin: '20px 0',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '20px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '10px' }}>
          <Typography>Обновить список потоков:</Typography>
          <IconButton onClick={loadStreams} aria-label='reload' disabled={loading}>
            <ReplayIcon />
          </IconButton>
        </Box>
        <Select value={limit} onChange={handleLimitChange}>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={1000}>1000</MenuItem>
        </Select>
      </Box>
      <TextField
        label='Поиск потоков..'
        variant='outlined'
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ margin: '20px 0' }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button onClick={() => handleRequestSort('name')}>
          <TableSortLabel active={orderBy === 'name'} direction={orderBy === 'name' ? order : 'asc'}>
            Название
          </TableSortLabel>
        </Button>

        <Button onClick={() => handleRequestSort('date')}>
          <TableSortLabel active={orderBy === 'date'} direction={orderBy === 'date' ? order : 'asc'}>
            По дате
          </TableSortLabel>
        </Button>

        <Button onClick={() => handleRequestSort('mode')}>
          <TableSortLabel active={orderBy === 'mode'} direction={orderBy === 'mode' ? order : 'asc'}>
            Режим
          </TableSortLabel>
        </Button>
      </Box>

      {loading && <CustomSkeleton />}
      {!loading && error && <Typography variant='h5'>Не удалось загрузить потоки по Вашему логину ;(</Typography>}
      {!loading && !error && streams.length === 0 && <Typography variant='h5'>Потоки не найдены</Typography>}
      {!loading && !error && streams.length > 0 && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {streams.map((stream) => (
              <PalladiumStreamItem
                key={stream.id}
                stream={stream}
                anchorEls={anchorEls}
                setAnchorEls={setAnchorEls}
                loadStreams={loadStreams}
                isBlock={stream.part_integration}
              />
            ))}
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{ marginTop: '20px' }}
            color='primary'
            siblingCount={1}
            boundaryCount={1}
          />
        </>
      )}
    </>
  );
};
