import io from 'socket.io-client';

const connectToSocket = () => {
  const socket = io(process.env.NODE_ENV === 'development' ? `ws://localhost:1337` : `wss://2leads.dev:3033`);

  socket.on('connect', () => {
    console.log('Connected to server');
  });

  socket.on('disconnect', () => {
    console.log('Disconnected from server');
  });

  return socket;
};

export default connectToSocket;
