const timezones = [
  '-7:00',
  '-4:00',
  '-3:00',
  '-2:00',
  '-1:00',
  '0:00',
  '+1:00',
  '+2:00',
  '+3:00',
  '+4:00',
  '+7:00',
  '+8:00',
  '+11:00',
  '+13:00',
];
export default timezones;
