import React, { useEffect, useState } from "react";
import mainAPI from "../../utils/mainAPI";
import { Chart, Line } from "react-chartjs-2";
import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Typography,
} from "@mui/material";
import networks from "../../assets/networks";
import geo from "../../assets/geo";
import Slider from "@mui/material/Slider";
import zoomPlugin from "chartjs-plugin-zoom";
import DatePicker from "react-date-picker";

function EPCChart() {
  const [selectedGeo, setSelectedGeo] = useState("DE");
  const [loadedData, setLoadedData] = useState([]);
  const [chartData, setChartData] = useState(null);
  const [chartAverageData, setChartAverageData] = useState(null);
  const [datePickerDate, setDatePickerDate] = useState(new Date());
  const [tension, setTenstion] = useState(0.3);
  const [loadedNetworks, setLoadedNetworks] = useState([]);
  const currentDate = new Date();

  useEffect(async () => {
    Chart.register(zoomPlugin);
    document.title = "2leads App - EPC";
    const formatMap = {
      mm: currentDate.getMonth() + 1,
      dd: currentDate.getDate(),
      yy: currentDate.getFullYear().toString().slice(-2),
      yyyy: currentDate.getFullYear(),
    };
    const result = [
      formatMap.yyyy,
      formatMap.mm < 10 ? "0" + formatMap.mm : formatMap.mm,
      formatMap.dd,
    ];
    await mainAPI
      .get(
        "/primary/epc?year=" +
          result[0] +
          "&month=" +
          result[1] +
          "&day=" +
          result[2]
      )
      .then((res) => {
        if (res.data) {
          setLoadedData(res.data);
        }
      });
    mainAPI.get("/bot/networks").then((res) => {
      let tempNetworks = [];

      setLoadedNetworks(res.data);
    });
  }, []);

  useEffect(async () => {
    await prepareChartData();
    await prepareChartAverageData();
    Chart.register(zoomPlugin);
  }, [loadedData, tension]);

  function changeDate(date) {
    setDatePickerDate(date);
    const formatMap = {
      mm: date.getMonth() + 1,
      dd: date.getDate(),
      yy: date.getFullYear().toString().slice(-2),
      yyyy: date.getFullYear(),
    };
    const result = [
      formatMap.yyyy,
      formatMap.mm < 10 ? "0" + formatMap.mm : formatMap.mm,
      formatMap.dd,
    ];
    mainAPI
      .get(
        "/primary/epc?year=" +
          result[0] +
          "&month=" +
          result[1] +
          "&day=" +
          result[2]
      )
      .then((res) => {
        if (res.status === 200) {
          setLoadedData(res.data);
        }
      });
  }

  const colors = [
    "rgb(121,226,78)",
    "rgb(226,113,78)",
    "rgb(172,139,255)",
    "rgb(255,0,0)",
    "rgb(255,255,255)",
    "rgb(121,12,78)",
    "rgb(55,113,78)",
    "rgb(5,88,255)",
    "rgb(53,0,0)",
    "rgb(255,14,255)",
    "rgb(121,226,78)",
    "rgb(226,113,78)",
    "rgb(172,139,255)",
    "rgb(255,0,0)",
    "rgb(255,255,255)",
  ];
  function getNameFromId(id) {
    let x = loadedNetworks.filter((item) => id == item.binom_2_id);
    return x[0].name;
  }
  function prepareChartData() {
    const result = {
      labels: [],
      datasets: [],
    };
    const tempResult = {};
    const allLabels = [];

    try {
      loadedData.forEach((item) => {
        const { data } = item;
        result.labels.push(item.hour + ":59");
        let counter = 0;
        if (data) {
          for (let k in data[selectedGeo]) {
            counter++;

            tempResult[k] = { leads: [], data: [], lpclicks: [], revenue: [] };
          }
        }
      });

      for (let key in tempResult) {
        const tempEpc = [];
        loadedData.forEach((item, index) => {
          tempResult[key].tension = tension;
          tempResult[key].label = key;
          tempResult[key].leads.push(
            item.data[selectedGeo][key]?.leads || null
          );
          // tempResult[key].data.push(item.data[selectedGeo][key]?.epc||null)
          tempResult[key].lpclicks.push(
            item.data[selectedGeo][key]?.lpclicks || null
          );
          tempResult[key].revenue.push(
            item.data[selectedGeo][key]?.revenue || null
          );
        });
      }

      for (let key in tempResult) {
        result.datasets.push(tempResult[key]);
      }

      result.datasets.forEach((item, index) => {
        item.backgroundColor = colors[index];
        item.borderColor = colors[index];
        item.label = getNameFromId(item.label);
      });

      result.datasets.forEach((item, index) => {
        const newLeads = [];
        item.leads.forEach((leads, index) => {
          if (index === 0) {
            newLeads.push(item.leads[index]);
          } else {
            newLeads.push(item.leads[index] - item.leads[index - 1]);
          }
        });
        item.leads = newLeads;
      });
      result.datasets.forEach((item, index) => {
        const newEpc = [];
        item.leads.forEach((leads, index) => {
          if (index === 0) {
            newEpc.push(
              Math.floor((item.revenue[0] / item.lpclicks[0]) * 100) / 100
            );
          } else {
            newEpc.push(
              Math.floor(
                ((item.revenue[index] - item.revenue[index - 1]) /
                  (item.lpclicks[index] - item.lpclicks[index - 1])) *
                  100
              ) / 100
            );
          }
        });
        item.data = newEpc;
      });
    } catch (e) {}
    setChartData(result);
  }

  async function prepareChartAverageData() {
    const result = {
      labels: [],
      datasets: [],
    };
    const tempResult = {};
    const allLabels = [];

    loadedData.forEach((item) => {
      const { data } = item;
      result.labels.push(item.hour + ":59");
      let counter = 0;
      if (data) {
        for (let k in data[selectedGeo]) {
          counter++;

          tempResult[k] = { leads: [], data: [], lpclicks: [], revenue: [] };
        }
      } else console.log(item);
    });

    for (let key in tempResult) {
      const tempEpc = [];
      loadedData.forEach((item, index) => {
        tempResult[key].tension = tension;
        tempResult[key].label = key;

        tempResult[key].leads.push(item.data[selectedGeo][key]?.leads || null);
        tempResult[key].data.push(item.data[selectedGeo][key]?.epc || null);
        tempResult[key].lpclicks.push(
          item.data[selectedGeo][key]?.lpclicks || null
        );
        tempResult[key].revenue.push(
          item.data[selectedGeo][key]?.revenue || null
        );
      });
    }
    for (let key in tempResult) {
      result.datasets.push(tempResult[key]);
    }

    await result.datasets.forEach((item, index) => {
      item.backgroundColor = colors[index];
      item.borderColor = colors[index];
      item.label = getNameFromId(item.label);
      if (index === result.datasets.length) {
        return Promise.resolve();
      }
    });

    result.datasets.forEach((item, index) => {
      const newLeads = [];

      item.leads.forEach((leads, index) => {
        if (index === 0) {
          newLeads.push(item.leads[index]);
        } else {
          newLeads.push(item.leads[index] - item.leads[index - 1]);
        }
      });
      item.leads = newLeads;
    });
    result.datasets.forEach((item, index) => {
      const newEpc = [];

      item.leads.forEach((leads, index) => {
        if (index === 0) {
          newEpc.push(
            Math.floor((item.revenue[0] / item.lpclicks[0]) * 100) / 100
          );
        } else {
          newEpc.push(
            Math.floor((item.revenue[index] / item.lpclicks[index]) * 100) / 100
          );
        }
      });
      item.data = newEpc;
    });

    setChartAverageData(result);
  }

  const options = {
    scales: {
      y: {
        grid: {
          color: "rgba(65,65,65,0.5)",
        },
        ticks: {
          color: "#fff",
          // Include a dollar sign in the ticks
          callback: function (value, index, values) {
            return "$" + Math.floor(value * 100) / 100;
          },
        },
      },
      x: {
        grid: {
          color: "rgba(65,65,65,0.5)",
        },
        ticks: {
          color: "#fff",
        },
      },
    },
    plugins: {
      title: {
        color: "#fff",
      },
      legend: {
        labels: {
          color: "#fff",
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            const leads = context.dataset.leads[context.dataIndex] || null;
            if (leads) {
              return label + ", Leads: +" + leads;
            } else {
              return label + ", Leads: +0";
            }
          },
          footer: function (context) {},
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "x",
        },
        limits: {
          y: { min: 0, max: 30 },
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.05,
          },
          drag: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "y",
        },
      },
    },
  };
  const valuetext = 10;

  return (
    <>
      <Typography variant="h4" style={{ textAlign: "left", marginLeft: 50 }}>
        EPC График
      </Typography>

      <Container>
        <Paper
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
            padding: "10px",
          }}
        >
          <FormControl sx={{ width: "100px", display: "block" }}>
            <InputLabel id="demo-simple-select-label">Гео</InputLabel>
            <Select
              sx={{ color: "#fff" }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedGeo}
              label="Гео "
              onChange={(e) => setSelectedGeo(e.target.value)}
            >
              {geo.map((item) => {
                return (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <DatePicker
            style=".react-date-picker__inputGroup__input {color:#fff}"
            value={datePickerDate}
            onChange={changeDate}
          />

          <Slider
            aria-label="Сглаживание графика"
            defaultValue={0.3}
            valueLabelDisplay="auto"
            step={0.1}
            marks
            value={tension}
            onChange={(e) => {
              setTenstion(e.target.value);
            }}
            min={0}
            max={0.5}
            sx={{ width: "150px", marginLeft: "25px" }}
          />
        </Paper>
        <Typography
          variant="h5"
          style={{ textAlign: "center", marginLeft: 50 }}
          sx={{ p: 3 }}
        >
          Среднесуточный
        </Typography>

        {loadedData.length ? (
          <Paper sx={{ p: 2 }}>
            <Line
              data={chartAverageData}
              options={options}
              height="120"
              style={{ zIndex: 100 }}
            />
          </Paper>
        ) : (
          <Skeleton height={600} width="100%" />
        )}
        <Typography
          variant="h5"
          style={{ textAlign: "center", marginLeft: 50 }}
          sx={{ p: 3 }}
        >
          За каждый час
        </Typography>
        {loadedData.length ? (
          <Paper sx={{ p: 2 }}>
            <Line data={chartData} options={options} height="120" />
          </Paper>
        ) : (
          <Skeleton height={600} width="100%" />
        )}
      </Container>
    </>
  );
}

export default EPCChart;
